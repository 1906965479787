import React from "react";
import { motion } from "framer-motion";
import Navbar from "./../../components/Navbar/index";
import ghurainna from "../../images/svg/ghurainna.svg";
import human from "../../images/svg/humansitting.svg";
import humanback from "../../images/svg/backgroundhuman.svg";
import arrow from "../../images/svg/arrow.svg";
import ParticlesComponent from "../../components/Particlejs/ParticlesCompnent";

const Homepage = () => {
  return (
    <div className="home bg-[#1d2127] pt-20 pb-11">
      <Navbar />
      <ParticlesComponent />

      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
        className="text_frame_row mx-8 mt-8 relative"
      >
        <div className="textframe flex flex-row justify-center text-center">
          <div className="textframe__text flex flex-col align-center text-xl">
            <p className="textframe__text--p1 text-center font-bold text-[#eeeeee] font-poppins">
              CODE CRAFTSMAN:
            </p>
            <p className="textframe__text--p2 text-center text-[#00adb5] text-5xl font-black">
              FULLSTACK EDITION
            </p>
            <div className="button1 my-3"></div>
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 1, duration: 0.5 }}
              className="ghurainna absolute"
            >
              <img src={ghurainna} alt="Your SVG" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1, duration: 0.5 }}
              className="arrow flex justify-center"
            >
              <img src={arrow} alt="Your SVG" />
            </motion.div>
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.5, duration: 0.5 }}
          className="container overflow-clip flex flex-row justify-center"
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2, duration: 0.5 }}
            className="human flex justify-center"
          >
            <motion.img
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ delay: 2, duration: 0.5 }}
              className="size-2xl z-40"
              src={human}
              alt="human"
            />
          </motion.div>
          <div className="back absolute h-md w-md">
            <img className="w-md" src={humanback} alt="human" />
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Homepage;
