// ParticlesComponent.js
import React, { useEffect } from "react";
import "particles.js";
import particlesConfig from "./particles.json"; // Import the JSON file

const ParticlesComponent = () => {
  useEffect(() => {
    window.particlesJS("particles-js", particlesConfig, function () {
      console.log("callback - particles-js config loaded");
    });
  }, []);

  return (
    <div
      id="particles-js"
      className=" z-40 h-[90%] md:h-[90%] lg:h-[90%] xl:h-[110%]"
      style={{
        position: "absolute",
        // width: "100%",
        // height: "90%",
        top: "0",
        left: "0",
      }}
    ></div>
  );
};

export default ParticlesComponent;
