import React from 'react';
import { Link } from 'react-scroll';
import './navbar.css';




const Navbar = () => {
  return (
    <div className="navbar fixed  top-0 w-full z-50">
      <div className="navbar__frame1">
        <h2 className="navbar__frame1__sharifsdevlab">SharifsDevLab</h2>
      </div>

      <div className="navbar__frame2">
        <ul className="navbar__frame2">
          <li className="navbar__frame2__navbar-item">
            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              
            >
              Home
            </Link>
          </li>

          <li className="navbar__frame2__navbar-item">
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              About
            </Link>
          </li>
          <li className="navbar__frame2__navbar-item">
            <Link
              to="projects"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Projects
            </Link>
          </li>

          <li className="navbar__frame2__navbar-item">
            <Link
              to="education"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Education
            </Link>
          </li>
          <li className="navbar__frame2__navbar-item">
            <Link
              to="certifications"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Certifications
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
