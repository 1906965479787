import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation"; // Import navigation module
import "swiper/css/autoplay"; // Import autoplay module
import "./Swiper.css";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";

const Swiperr = () => {
  return (
    <div className="bg-[#1D2127]">
      <div className="flex flex-row justify-center font-poppins text-[#00adb5] text-5xl font-black pt-10">
        Skills
      </div>
      <div className="flex flex-row justify-center align-middle ">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          navigation={true} // Enable navigation
          autoplay={{
            delay: 1600, // Delay between transitions in milliseconds
            disableOnInteraction: false, // Enable/disable autoplay on user interaction
          }}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className="mySwiper"
        >
          {/* Slides */}
          <SwiperSlide>
            <img
              className=" hover:scale-105 ease-in duration-300"
              src="https://faizul-islam.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffigma.86178dbe.png&w=256&q=75"
              alt="slide 1"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className=" hover:scale-105 ease-in duration-300"
              src="https://faizul-islam.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fjavascript.c1c7776c.png&w=256&q=75"
              alt="slide 2"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className=" hover:scale-105 ease-in duration-300"
              src="https://faizul-islam.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffirebase.8ec987e0.png&w=256&q=75"
              alt="slide 3"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className=" hover:scale-105 ease-in duration-300"
              src="https://faizul-islam.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fgit.37625c0a.png&w=384&q=75"
              alt="slide 4"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className=" hover:scale-105 ease-in duration-300"
              src="https://faizul-islam.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fgithub.f0bbc6e8.png&w=640&q=75"
              alt="slide 5"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className=" hover:scale-105 ease-in duration-300"
              src="https://faizul-islam.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fhtml5.e89c02ec.png&w=256&q=75"
              alt="slide 6"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className=" hover:scale-105 ease-in duration-300"
              src="https://faizul-islam.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmongodb.155fdbaf.png&w=256&q=75"
              alt="slide 7"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className=" hover:scale-105 ease-in duration-300"
              src="https://faizul-islam.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fpython.4471a68c.png&w=1920&q=75"
              alt="slide 8"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className=" hover:scale-105 ease-in duration-300"
              src="https://faizul-islam.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Freactjs.3ac94d47.png&w=256&q=75"
              alt="slide 9"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className=" hover:scale-105 ease-in duration-300"
              src="https://images.ctfassets.net/e5382hct74si/76huO0U4LZC7JDYn9ATcua/7e73799e55400fa274d56bfd4b44caa2/django.png"
              alt="slide 9"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className=" hover:scale-105 ease-in duration-300"
              src="https://sqlite.org/forum/logo?id=603c155e"
              alt="slide 9"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Swiperr;
