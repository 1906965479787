import React from 'react';
import Homepage from './../Homepage/Homepage';
import Aboutme from './../Aboutmepage/Aboutme';
import Projectpage from '../Projectspage/Projectpage';
import Education from '../Educationpage/Education';
import Footer from '../Footer/Footer';
import Skills from '../Skillspage/Skills';
import Certifications from '../Certifications/Certifications';
import Contact from '../Contactpage/Contact';

const Finalpage = () => {
    return (
      <div>
        <Homepage />
        <Aboutme />
        <Skills />
        <Projectpage />
        <Education />
        <Certifications />
        <Contact />
      </div>
    );
};

export default Finalpage;