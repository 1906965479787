import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation"; // Import navigation module
import "swiper/css/autoplay"; // Import autoplay module

import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";

const  Certifications= () => {
  return (
    <div className="bg-[#1D2127] certifications">
      <div className="flex flex-row justify-center font-poppins text-[#00adb5] text-5xl font-black pt-10">
        Certifications
      </div>
      <div className="flex flex-row justify-center align-middle ">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          navigation={true} // Enable navigation
          autoplay={{
            delay: 1600, // Delay between transitions in milliseconds
            disableOnInteraction: false, // Enable/disable autoplay on user interaction
          }}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className="mySwiper"
        >
          {/* Slides */}
          <SwiperSlide>
            <img
              src="https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~YJF49PNLHSVC/CERTIFICATE_LANDING_PAGE~YJF49PNLHSVC.jpeg"
              alt="slide 1"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~BCEHGGW2UR3U/CERTIFICATE_LANDING_PAGE~BCEHGGW2UR3U.jpeg"
              alt="slide 2"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~BRH525D26NP7/CERTIFICATE_LANDING_PAGE~BRH525D26NP7.jpeg"
              alt="slide 3"
            />
          </SwiperSlide>
         
        </Swiper>
      </div>
    </div>
  );
};

export default Certifications;
