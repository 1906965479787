import React, { useState } from "react";
import human from "../../images/svg/human.svg";
import back from "../../images/svg/back.png";
import "./aboutme.css";

const Aboutme = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="about">
      <div className="textpart">
        <div className="text_aboutme">
          <p>About <span className="me">me</span></p>
        </div>

        <div className={`text_details ${showMore ? "show" : ""}`}>
          <p>
            I am a passionate web developer with a deep interest in building
            engaging and intuitive user experiences. My journey into web
            development began with a curiosity for how websites work and a
            desire to create digital spaces that are both beautiful and
            functional.
            <span className="fade-in-text">
              {showMore && (
                <>
                  {" "}
                  I have experience in front-end technologies such as React, as well as back-end technologies like Node.js ,Django. 
                  I enjoy
                  turning ideas into reality through coding and believe in
                  continuous learning to stay up-to-date with the latest
                  technologies in the ever-evolving web development landscape.{" "}
                </>
              )}
            </span>
            <span className="read-more text-zinc-300" onClick={toggleShowMore}>
              {showMore ? "...Read less" : "...Read more"}
            </span>
          </p>
        </div>
      </div>

      <div className="image">
        <img className="z-10" src={human} alt="human" />
        <img src={back} alt="back" />
      </div>
    </div>
  );
};

export default Aboutme;
