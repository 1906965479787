import React from "react";
import img1 from "./../../images/svg/img1.png"
import img2 from "./../../images/svg/img2.png"
import img3 from "./../../images/svg/img3.png"
import "./Projectpage.css";


const Projectpage = () => {
  return (
    <div className="projects">
      <div className="text">
        <h2>
          My recent <span>works</span>
        </h2>
      </div>

      <div className="projects_images">
        <div className="project1">
          <a href="https://sharif2552.pythonanywhere.com/" target="_blank">
            <img src={img1} alt="img1" />
            <div>
              <h3>E-Learning Website</h3>
            </div>
          </a>
        </div>
        <div className="project2">
          <a href="https://portfolio-five-blush-27.vercel.app/" target="_blank">
            <img src={img2} alt="" />
            <div>
              <h3>Online Portfolio Website</h3>
            </div>
          </a>
        </div>
        <div className="project3">
          <a href="https://ecommerce159951.pythonanywhere.com/" target="_blank">
            <img src={img3} alt="" />
            <div>
              <h3>Ecommerce Website</h3>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Projectpage;
