import React from 'react';
import Swiperr from '../../components/Swiper/Swiperr';

const Skills = () => {
    return (
      <div className=''>
        <Swiperr  />
      </div>
    );
};

export default Skills;