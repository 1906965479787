

import Finalpage from './pages/Finalpage/Finalpage';

function App() {
  return (
    <div className="App">
      <Finalpage />
    </div>
  );
}

export default App;
