import React from "react";
import "./Education.css"; // Import the CSS file

const Education = () => {
  return (
    <div className="education">
      <div className="education-container">
        <div className="education-heading">Education</div>
        <div className="education-details">
          Studying Bachelor of Science in Engineering (B.Sc. Engg.) in
          <span className="education-highlight">
            {" "}
            Electrical & Computer Engineering
          </span>
        </div>
        <div className="education-university">
          Rajshahi University of Engineering & Technology
          <span>
            (<span className="education-highlight">RUET</span>)
          </span>
        </div>
      </div>
    </div>
  );
};

export default Education;
